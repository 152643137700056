import { ProjectHeaderWidgetComponentIds } from '../../projectHeaderWidget/config/constants';
import projectPreset1 from '../../../assets/media/project-page-presets/1.png';
import projectPreset2 from '../../../assets/media/project-page-presets/2.png';
import projectPreset3 from '../../../assets/media/project-page-presets/3.png';
import projectPreset4 from '../../../assets/media/project-page-presets/4.png';
import projectPreset5 from '../../../assets/media/project-page-presets/5.png';
import projectPreset6 from '../../../assets/media/project-page-presets/6.png';
import projectPreset7 from '../../../assets/media/project-page-presets/7.png';
import projectPreset8 from '../../../assets/media/project-page-presets/8.png';

export enum ProjectPageWidgetComponentIds {
  Root = '#projectPageWidgetRoot',
  ProjectWidget1 = '#projectWidget1',
  EmptyStateWidget = '#projectEmptyStateWidget1',
}

export enum ProjectPageWidgetEvents {}

export const ProjectPageWidgetTabsArray = [
  {
    label: 'Project_Design_Panel_Title',
    roles: [ProjectHeaderWidgetComponentIds.Title],
    skipOnEditorX: true,
  },
  {
    label: 'Project_Design_Panel_Details_Label',
    roles: ['#detailLabel'],
    skipOnEditorX: true,
  },
  {
    label: 'Project_Design_Panel_Details_Text',
    roles: ['#detailText'],
    skipOnEditorX: true,
  },
  {
    label: 'Project_Design_Panel_Description',
    roles: [ProjectHeaderWidgetComponentIds.Description],
    skipOnEditorX: true,
  },
  {
    label: 'Project_Design_Panel_Hero_Image',
    roles: [ProjectHeaderWidgetComponentIds.HeroImage],
  },
  {
    label: 'Project_Design_Panel_Page_Background',
    roles: ['#projectPageWidgetRoot'],
  },
  {
    label: 'Project_Design_Panel_Navigation_Buttons',
    roles: ['#navigationButton'],
    skipOnEditorX: true,
  },
  // {
  //   label: 'Project_Design_Panel_Gallery',

  //   roles: [ProjectWidgetComponentIds.Gallery],
  // },
];

export const ProjectPageWidgetPresets = [
  {
    // 1.2
    id: 'externalPreset-variants-l9v9bw1g',
    connectedMobilePresetID: 'variants-l71pcd2c',
    src: projectPreset1,
    layout: {
      width: '114px',
      height: '154px',
    },
  },
  {
    // 2.1
    id: 'externalPreset-variants-l71toaf3',
    connectedMobilePresetID: 'variants-l71tr32a',
    src: projectPreset2,
    layout: {
      width: '114px',
      height: '154px',
    },
  },
  {
    // 3.1
    id: 'externalPreset-variants-l74qktjj',
    connectedMobilePresetID: 'variants-l71s8wjq',
    src: projectPreset3,
    layout: {
      width: '114px',
      height: '154px',
    },
  },
  {
    // 4.1
    id: 'externalPreset-variants-l71uhe49',
    connectedMobilePresetID: 'variants-l71vneg0',
    src: projectPreset4,
    layout: {
      width: '114px',
      height: '154px',
    },
  },
  {
    // 5.1
    id: 'externalPreset-variants-l71x7bcn',
    connectedMobilePresetID: 'variants-l724u4q0',
    src: projectPreset5,
    layout: {
      width: '114px',
      height: '154px',
    },
  },
  {
    // 6.1
    id: 'externalPreset-variants-l728gqx9',
    connectedMobilePresetID: 'variants-l728le0g',
    src: projectPreset6,
    layout: {
      width: '114px',
      height: '154px',
    },
  },
  {
    // 7.1
    id: 'externalPreset-variants-l72a759d',
    connectedMobilePresetID: 'variants-l72a9j26',
    src: projectPreset7,
    layout: {
      width: '114px',
      height: '154px',
    },
  },
  {
    // 8.1
    id: 'externalPreset-variants-l7366shq',
    connectedMobilePresetID: 'variants-l73bsbwq',
    src: projectPreset8,
    layout: {
      width: '114px',
      height: '154px',
    },
  },
];
