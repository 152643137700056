import model from './model';
import { ProjectPageWidgetComponentIds as ComponentIds } from './config/constants';
import { GetProjectPageDataResponse } from '@wix/ambassador-portfolio-projects-v1-project/types';
import { Item } from '@wix/ambassador-portfolio-project-items-v1-project-item/types';
import {
  getCustomViewerHttpClient,
  getPageViewMode,
} from '../../utils/viewerUtils';
import { portfolioPageViewed } from '@wix/bi-logger-portfolio-users/v2';
import { getProjectBySlug, getProjectItems } from '../../services/dataServices';

export interface ProjectPageData extends GetProjectPageDataResponse {
  projectItems?: Item[];
}

export default model.createController((controllerParams) => {
  const { $w, $widget, flowAPI } = controllerParams;
  const { isEditor, isViewer } = flowAPI.environment;
  const { httpClient } = getCustomViewerHttpClient(flowAPI);
  const biLogger = flowAPI.essentials.biLoggerFactory().logger();
  let components: ReturnType<typeof getComponents>;
  let routerData: any = {};
  let projectPageData: ProjectPageData = {};

  const getComponents = () => ({
    topContainer: $w(ComponentIds.Root),
    projectWidget1: $w(ComponentIds.ProjectWidget1),
    emptyStateWidget: $w(ComponentIds.EmptyStateWidget),
  });

  const initProjectPageData = async () => {
    const projectWidgetData = {
      project: projectPageData?.project,
      items: projectPageData?.projectItems,
      nextProjectSlug: projectPageData.nextProject?.slug || '',
      prevProjectSlug: projectPageData.previousProject?.slug || '',
    };
    components.projectWidget1.data = projectWidgetData;
  };

  const showEmptyStateIfNeeded = async () => {
    if (!isEditor) {
      return false;
    }
    let isEmptyStateDisplayed = false;
    if (routerData && !projectPageData?.project?.id) {
      await components.emptyStateWidget.expand();
      await components.projectWidget1.collapse();
      isEmptyStateDisplayed = true;
    } else if (routerData && projectPageData?.project?.id) {
      await components.emptyStateWidget.collapse();
      await components.projectWidget1.expand();
    }
    return isEmptyStateDisplayed;
  };

  const initData = async () => {
    routerData =
      await flowAPI.controllerConfig.wixCodeApi.window.getRouterPublicData();
    projectPageData = routerData?.projectPageData || {};
    if (!projectPageData.project && isViewer) {
      projectPageData = await getProjectBySlug(flowAPI);
    }

    if (!projectPageData.projectItems && projectPageData.project?.id) {
      projectPageData.projectItems = await getProjectItems(
        httpClient,
        projectPageData.project.id,
      );
    }
  };

  const initProjectPage = async () => {
    await components.emptyStateWidget.collapse();
    await initData();
    const isEmptyStateDisplayed = await showEmptyStateIfNeeded();
    if (!isEmptyStateDisplayed) {
      await initProjectPageData();
    }
  };

  return {
    pageReady: async () => {
      components = getComponents();
      const isWidgetOnStage = !!components.topContainer.id;
      if (isWidgetOnStage) {
        await initProjectPage();

        biLogger.report(
          portfolioPageViewed({
            pageName: 'project',
            viewMode: getPageViewMode(flowAPI),
          }),
        );

        flowAPI.controllerConfig.wixCodeApi.seo.renderSEOTags({
          itemType: 'PORTFOLIO_PROJECTS',
          itemData: {
            project: projectPageData.project,
            projectItems: projectPageData.projectItems,
          },
          seoData: projectPageData?.project?.seoData || {},
        });
      }
    },
    exports: {},
  };
});
